<template>
  <el-card class="box-card" shadow="never">
    <div slot="header">
      <span>🥳 每日一句</span>
    </div>
    <div>
      <img :src="tip.picture2" style="border-radius: 5px;">
      <div class="has-text-left block" style="font-size: 15px; font-weight: bold; color: #333;">
        {{ tip.contentEnglish }} <!-- 英文内容 -->
      </div>
      <div class="has-text-left block" style="font-size: 16px; color: #666;">
        {{ tip.content }} <!-- 中文内容 -->
      </div>
      <!--      <div class="has-text-right mt-5 block">
              ——{{ tip.author }}
            </div>-->
      <el-button type="primary" @click="playAudio">播放</el-button> <!-- 播放按钮 -->
      <audio ref="audio" :src="tip.tts"></audio> <!-- 音频 -->
    </div>
  </el-card>
</template>

<script>
import {getTodayTip} from '@/api/tip'

export default {
  name: 'Tip',
  data() {
    return {
      tip: {
        tts: ''
      }
    }
  },
  created() {
    this.fetchTodayTip() // 获取每日一句
  },
  methods: {
    fetchTodayTip() {
      const today = new Date().toISOString().slice(0, 10);
      getTodayTip(today).then(response => {
        const { data } = response
        this.tip = data
      })
    },
    playAudio() {
      this.$refs.audio.play(); // 播放音频
    }
  }
}
</script>

<style scoped>

</style>
